export default class VerificationApi {
    static async checkValidPayloadForGifting(payload) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/verification/v1/gifting?payload=${encodeURIComponent(payload)}`, {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors'
        });

        return await response;
    }

    static async sendVerificationForGifting(payload, identityToken) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/verification/v1/gifting`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json;charset=UTF-8',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify({
                payload: payload,
                identityToken: identityToken
            }),
            cache: 'no-cache',
            mode: 'cors'
        });

        return await response;
    }
}