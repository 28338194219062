import React, { Component } from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Gifting from 'components/gifting/gifting';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router basename={'/'}>
          <div id="wrapper" role="main">
            <Route exact path="/" render={() => <div></div>}/>
            <Route exact path="/gifting" component={Gifting} />
          </div>
        </Router>

      </div>
    );
  }
}

export default App;
