import React, {Component} from 'react'
import VerificationApi from 'api/verificationApi'
import {startIdentityAuth} from '@riotgames/kr-identity-sdk'
import {BAD_REQUEST} from 'http-status-codes'

import RIOT_LOGO from 'assets/image/lnb_txt01.jpg';
import GIFTING from 'assets/image/gift_logo.jpg';
import './gifting.scss'

const queryString = require('query-string');

class Gifting extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        if (navigator.appVersion.indexOf("MSIE 8") !== -1 || navigator.appVersion.indexOf("MSIE 9") !== -1) {
            this.setState({notice: this._renderInvalidBrowser()})
            return;
        }

        const payload = queryString.parse(this.props.location.search).payload;
        VerificationApi.checkValidPayloadForGifting(payload)
            .then(response => {
                if (response.ok) {
                    return response.text()
                }
                throw {code: response.status}
            })
            .then(payload => this._openIdentityVerification(payload))
            .catch((e) => this._handleError(e.code))
    }

    _openIdentityVerification(payload) {
        startIdentityAuth({
          serviceType: 'VERIFY_ID',
          environment: process.env.REACT_APP_IDENTITY_ENV,
          isPopup: true,
          successCallback: (identityData) => {
          VerificationApi.sendVerificationForGifting(payload, identityData.identityToken)
          .then(response => response.ok ? this.setState({notice: this._renderComplete()}) : this._handleError(response.status))
          .catch(() => this._handleError())
          },
          failureCallback: () => { this._handleError() }
       });
    }

    _handleError(code) {
        if (code === BAD_REQUEST) {
            this.setState({notice: this._renderInvalidParam()});
            return;
        }

        this.setState({notice: this._renderError()});
    }

    _renderComplete() {
        return (<p className="notice">본인인증이 완료되었습니다.<br/>선물하기를 이용하실 수 있습니다.</p>);
    }

    _renderInvalidParam() {
        return (<p className="notice">인증 정보가 소환사님의 정보와 일치하지 않습니다.<br/>다시 시도해 주십시오.</p>);
    }

    _renderError() {
        return (<p className="notice">예상치 못한 에러가 발생했습니다.<br/>다시 시도해 주십시오.</p>);
    }

    _renderInvalidBrowser() {
        return (<p className="notice">IE10 이하의 브라우저에서는 사용이 제한됩니다.<br/>다시 시도해 주십시오.</p>);
    }

    render() {
        if (this.state.notice === undefined) {
            return (<div></div>);
        }

        return (
            <div id="auth_container_wrap" className="wd_412">
                <div className="auth_con">
                    <p><img src={RIOT_LOGO} alt="RIOT GAMES KOREA"/></p>
                    <h1 className="rp_pd"><img src={GIFTING} alt="라이엇포인트 RP 선물하기"/></h1>
                    {this.state.notice}
                </div>
            </div>
        )
    }
}

export default Gifting
